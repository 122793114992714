import React, { useState } from "react"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"

class EmailForm extends React.Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      message: "",
      success: false,
    }
    this.initialState = this.state
  }
  // const initialState = { }

  handleSubmit = event => {
    event.preventDefault()
    const functionUri =
      "https://europe-west3-ardent-pact-300617.cloudfunctions.net/contact-form"

    const requestBody = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      message: this.state.message,
    }

    fetch(functionUri, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(response => {
      response.json().then(data => {
        if (data?.success === true) {
          this.reset()
        }
      })
    })
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  reset = () => {
    this.setState(this.initialState)
    this.setState({ success: true })
  }

  render() {
    return (
      <Form action="" method="POST" onSubmit={this.handleSubmit}>
        <Row>
          <Col>
            <Form.Group controlId="firstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                name="firstName"
                value={this.state.firstName}
                placeholder="Enter first name"
                onChange={this.handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="lastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                name="lastName"
                value={this.state.lastName}
                placeholder="Enter last name"
                onChange={this.handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="message">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                name="message"
                value={this.state.message}
                placeholder="Enter your message"
                onChange={this.handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="email">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={this.state.email}
                placeholder="email@example.com"
                onChange={this.handleChange}
                required
              />
              <Form.Text className="text-muted">
                We will never share your email with someone else.
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="">
            <Button
              className="mx-auto"
              variant={this.state.success ? "success" : "dark"}
              disabled={this.state.success}
              type="submit"
            >
              {this.state.success ? "Success!" : "Submit"}
            </Button>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default EmailForm
