import React from "react"

import Layout from "../components/layout"
import Container from "react-bootstrap/Container"
import { Row, Col } from "react-bootstrap"
import SEO from "../components/seo"
import EmailForm from "../components/emailForm"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact Luk Vermeulen" />
    <Container>
      <Row className="my-5">
        <Col md="6" className="marketing">
          <h2>Hi!</h2>
          <p className="mt-3">
            Feel free to contact me about anything that crosses your mind!
            Please fill in the provided form with your name, message and email.
          </p>
          <p className="mt-3">I will come back to you ASAP.</p>
          <span className="my-auto"></span>
        </Col>
        <Col md="6">
          <EmailForm />
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default ContactPage
